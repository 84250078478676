export function lerp(start, end, t) {
  return start * (1 - t) + end * t;
}

export function capitalized(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function valueInArray(values, arr) {
  return arr.some((value) => values.indexOf(value) !== -1);
}

export function getItemIndex(items, arr) {
  let index;
  if (valueInArray(items, arr)) {
    items.forEach((item) => {
      if (arr.includes(item)) index = arr.indexOf(item);
    });
    return index;
  }
}

export function retrieveLanguage() {
  const pathElements = window.location.pathname.split("/");
  const languages = ["fr", "en"];
  const langIndex = getItemIndex(languages, pathElements);
  return pathElements[langIndex];
}

export function randomBetween(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export function randomTwoValues(min, max) {
  return Math.ceil((Math.random() - 0.5) * 2) < 1 ? min : max;
}

export function getBreakpoint() {
  return window
    .getComputedStyle(document.body, ":before")
    .content.replace(/\"/g, "");
}

export function bpOnResize(timeOut, bp) {
  window.addEventListener("resize", () => {
    if (!timeOut) {
      timeOut = setTimeout(function () {
        timeOut = null;
        bp = getBreakpoint();
        return bp;
      }, 100);
    }
  });
}

export function toggleClasses(el, condition, ...cls) {
  cls.map((cl) => el.classList.toggle(cl, condition));
}

export function toggleAttribute(el, condition, attr, value) {
  !condition ? el.removeAttribute(attr) : el.setAttribute(attr, value);
}

export function splitText(text) {
  const word = text.innerText.split("");
  text.innerHTML = "";
  return word;
}

export function populateText(word, text, data = "") {
  word.forEach((letter, i) => {
    text.innerHTML += `<span ${data} style="--index:${i}">${letter}</span>`;
  });
}

export function createElemWithClass({ elemTag = "div", className }) {
  const elem = document.createElement(elemTag);
  elem.setAttribute("class", className);
  return elem;
}

export function cloneAndReverseWord(parent) {
  const text = parent.children[0];
  const word = splitText(text);
  const wordReversed = word.map((w) => w).reverse();
  const textClone = parent.children[0].cloneNode(true);
  textClone.setAttribute("data-copy", "word");
  populateText(word, text, 'data-reverse="letter"');
  populateText(word, textClone, 'data-reverse="letter"');
  parent.appendChild(textClone);
}

export function changeOnTransitionEnd(parent, target, cls) {
  parent.addEventListener("mouseover", (e) => {
    const targetElem = e.target.closest(target);
    if (targetElem) {
      targetElem.addEventListener("transitionrun", () => {
        targetElem.classList.add(cls);
      });
      targetElem.addEventListener("transitionend", () => {
        targetElem.classList.remove(cls);
      });
    }
  });
}

export function countLines(elem) {
  const elemHeight = elem.getBoundingClientRect().height;
  const styles = window.getComputedStyle(elem);
  const lineHeight = Math.floor(
    parseInt(styles.getPropertyValue("line-height").replace("px", ""))
  );
  return Math.floor(elemHeight / lineHeight);
}

export function setSizeAttrToPicture(item) {
  const pict = item.closest("picture");
  pict.setAttribute("width", item.clientWidth);
  pict.setAttribute("height", item.clientHeight);
}

export function getDistanceToFocalPoint(elem, focalPoint = "center") {
  let dist;
  const rect = elem.getBoundingClientRect();
  if (focalPoint === "left" || focalPoint === "start") dist = rect.left;
  else if (focalPoint === "right" || focalPoint === "end") dist = rect.start;
  else dist = rect.left + rect.width / 2;
  return dist;
}

export function lazyLoadImages(images, options) {
  if (images.length === 0) return;
  if ("IntersectionObserver" in window) {
    const imagesObserver = new IntersectionObserver(
      (entries, imagesObserver) => {
        entries.forEach((entry) => {
          const image = entry.target;
          const plchldr = image.parentElement.querySelector(
            ".image__placeholder"
          );
          if (!entry.isIntersecting) return;
          const newSrc = image.getAttribute("data-src");
          const newSrcset = image.getAttribute("data-srcset");
          image.src = newSrc;
          image.srcset = newSrcset;
          image.onload = () => {
            image.classList.remove("lazyload");
            image.classList.add("is-loaded");
            setTimeout(() => {
              plchldr.classList.add("is-loaded");
            }, 200);
            setTimeout(() => {
              plchldr.remove();
            }, 1000);
          };
          imagesObserver.unobserve(image);
        });
      },
      options
    );

    images.forEach((image) => {
      imagesObserver.observe(image);
    });
  }
}

export function toggleTransition(item) {
  item.classList.add("no-transition");
  let addTransition = setInterval(() => {
    if (document.readyState === "complete") {
      clearInterval(addTransition);
      item.classList.remove("no-transition");
    }
  }, 200);
}

export function toggleSticky(item, parent, condition, bp) {
  if (bp === "sm" || bp === "xs") return;
  const parentTop = parent.getBoundingClientRect().top;
  const itemMargin = parseInt(
    window
      .getComputedStyle(item)
      .getPropertyValue("margin-top")
      .replace("px", "")
  );
  item.classList.toggle("sticky", !condition);
  if (condition) {
    item.style.top = `${(parentTop - itemMargin) * -1}px`;
  } else {
    item.style.removeProperty("top");
  }
}