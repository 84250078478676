import {
  toggleClasses,
  toggleAttribute,
  retrieveLanguage,
  cloneAndReverseWord,
} from "../utilities/utils";
import * as get from "../utilities/getters";
import barba from "@barba/core";

export class Helper {
  constructor(page) {
    this.page = page;
    this.wrapper = document.querySelector(".navbar__wrapper");
    this.navbar = this.wrapper.querySelector(".navbar");
    this.navbarContainer = this.wrapper.querySelector(".navbar__container");
    this.hamburger = this.wrapper.querySelector("#hamburger");
    this.pillsWrapper = this.wrapper.querySelector(".navbar__pills");
    this.pills = [...this.navbar.querySelectorAll("input")];
    this.links = [...this.navbar.querySelectorAll(".navbar__link")];
    this.languageLinks = [
      ...this.wrapper.querySelectorAll(".navbar__language"),
    ];
    this.searchBar = this.wrapper.querySelector(".search__wrapper");
    this.search = new get.SearchElements(this.searchBar, this.wrapper);

    this.placeWrapper = this.wrapper.querySelector(".place-toggle__wrapper");
    this.place = new get.PlacesElements(this.placeWrapper);
  }

  removeHomeClass() {
    this.navbar.classList.remove("navbar__home");
  }

  cloneNavLinks() {
    this.links.forEach((link) => {
      cloneAndReverseWord(link);
    });
  }

  setCurrentLanguage() {
    this.languageLinks.forEach((link) => {
      const linkLang = link.getAttribute("hreflang").toLowerCase();
      const shouldUpdate = retrieveLanguage() === linkLang;
      toggleClasses(link.firstElementChild, shouldUpdate, "is-active");
    });
  }

  setCurrentLink() {
    this.links.forEach((link) => {
      const pageLoc = link.getAttribute("data-loc");
      const shouldUpdate =
        (pageLoc && this.page.main.namespace === pageLoc) ||
        (pageLoc && this.page.main.parent === pageLoc);
      toggleClasses(link, shouldUpdate, "navbar__link--active", "is-active");
    });
  }

  toggleLinksFocus(target) {
    const shouldUpdate =
      target.checked || this.searchBar.classList.contains("is-active");
    this.links.forEach((link) => {
      toggleAttribute(link, shouldUpdate, "tabindex", "-1");
      toggleClasses(link, shouldUpdate, "is-invisible");
    });
    [...this.searchBar.children].forEach((child) => {
      toggleAttribute(child, !shouldUpdate, "tabindex", "-1");
    });
  }

  disableSearch() {
    toggleClasses(
      this.search.label,
      this.search.input.value === "",
      "is-disabled"
    );
    toggleAttribute(
      this.search.submit,
      this.search.input.value === "",
      "disabled",
      "true"
    );
  }

  toggleSearchBar(target) {
    toggleClasses(this.searchBar, target.checked, "is-active");
    toggleClasses(
      this.search.check.nextElementSibling.firstElementChild,
      target.checked,
      "is-active"
    );
    if (target.checked) this.search.input.focus();
    if (!target.checked && this.search.input.value !== "") {
      this.search.input.value = "";
      this.search.label.classList.add("is-disabled");
    }
  }

  activeSearchBar() {
    this.disableSearch();
    this.search.input.addEventListener("keyup", () => {
      this.disableSearch();
    });
    this.search.check.addEventListener("change", (e) => {
      this.toggleSearchBar(e.target);
      this.toggleLinksFocus(e.target);
      this.hamburger.checked = false;
      this.toggleMobileMenu();
    });
    this.search.close.addEventListener("click", (e) => {
      this.search.check.checked = false;
      this.toggleSearchBar(this.search.check);
      this.toggleLinksFocus(this.search.check);
      this.hamburger.checked = false;
      this.toggleMobileMenu();
    });
    this.search.check.addEventListener("keypress", (e) => {
      if (e.keyCode === 13) {
        this.toggleSearchBar(e.target);
        this.toggleLinksFocus(e.target);
        this.hamburger.checked = false;
        this.toggleMobileMenu();
      }
    });
  }

  toggleMobileMenu() {
    toggleClasses(this.navbarContainer, this.hamburger.checked, "is-open");
    toggleClasses(this.pillsWrapper, this.hamburger.checked, "is-open");
    this.links.forEach((link) =>
      toggleAttribute(link, !this.hamburger.checked, "tabindex", "-1")
    );
  }

  activeMobileMenu() {
    this.hamburger.addEventListener("change", (e) => {
      this.toggleMobileMenu();
    });
    this.search.check.addEventListener("keypress", (e) => {
      if (e.keyCode === 13) {
        e.target.checked = !e.target.checked;
        this.toggleMobileMenu();
      }
    });
  }

  resetPlaceLabel() {
    this.place.labels.forEach((label) => {
      label.firstElementChild.classList.remove("is-active");
      label.classList.remove("is-disabled");
    });
    this.place.inputs.forEach((input) => {
      if (!input.classList.contains("none")) {
        input.classList.remove("is-disabled");
      }
    });
  }

  updateLabelSizeAndPos(item) {
    if (!item) return;
    const { width, height } = item.getBoundingClientRect();
    this.place.noneLabel.style.left = `${item.offsetLeft}px`;
    this.place.noneLabel.style.width = `${width}px`;
    this.place.noneLabel.style.height = `${height}px`;
  }

  togglePlaceLabel(item, input) {
    const shouldUpdate = !input.classList.contains("none") && input.checked;
    toggleClasses(item.firstElementChild, input.checked, "is-active");
    toggleClasses(item, input.checked, "is-disabled");
    toggleClasses(input, input.checked, "is-disabled");
    toggleClasses(this.place.noneInput, !shouldUpdate, "is-disabled");
    toggleClasses(this.place.noneLabel, !shouldUpdate, "is-disabled");

    this.updateLabelSizeAndPos(this.place.labels[0]);
    if (shouldUpdate) {
      this.updateLabelSizeAndPos(item);
    }
  }

  togglePlaceArticle() {
    if (!this.page.exhibitList) return;
    const nameSpace = this.page.main.namespace;
    const exhibitLists = [...this.page.mainWrapper.querySelectorAll(".list")];
    if (!exhibitLists) return;
    const yearsList = [
      ...this.page.mainWrapper.querySelectorAll(".years-list__item"),
    ];
    exhibitLists.forEach((list) => {
      const exhibitItems = [...list.querySelectorAll("li")];
      const itemClass = nameSpace !== "home" ? "is-invisible" : "is-blured";

      exhibitItems.forEach((item) => {
        const input = this.place.inputs.find((input) => input.checked);
        const inputPlace = input.dataset.togglePlace;
        const itemPlace = item.firstElementChild.dataset.place;
        toggleClasses(
          item,
          inputPlace !== itemPlace && inputPlace !== "none",
          itemClass
        );
      });

      const visibleItems = exhibitItems.filter(
        (i) => !i.classList.contains("is-invisible")
      );

      if (yearsList.length !== 0) {
        const year = list.id;
        const yearTarget = yearsList.find(
          (target) => target.firstElementChild.dataset.loc === year
        );
        toggleClasses(yearTarget, visibleItems.length === 0, "is-disabled");
      }

      if (nameSpace === "search") {
        const count = this.page.mainWrapper.querySelector(
          '[data-search="count"]'
        );
        count.innerText =
          visibleItems.length > 0
            ? `${visibleItems.length} ${this.page.main.res}`
            : this.page.main.noMatch;
      }
    });
  }

  activePlace() {
    this.place.inputs.forEach((input, index) => {
      const activeItem = this.place.labels[index];
      this.togglePlaceLabel(activeItem, input);
      input.addEventListener("change", () => {
        this.resetPlaceLabel();
        this.togglePlaceLabel(activeItem, input);
        this.togglePlaceArticle();
      });
    });
    this.togglePlaceArticle();
  }

  reloadOnLangChange() {
    if (!this.languageLinks) return;
    this.languageLinks.forEach((link) => {
      link.addEventListener("click", () => {
        barba.force(link);
      });
    });
  }

  updateStickyOnMobile() {
    let timeOut;
    let lastScroll = 0;
    window.addEventListener(
      "scroll",
      () => {
        if (timeOut) window.cancelAnimationFrame(timeOut);
        timeOut = window.requestAnimationFrame(() => {
          const currentScroll = window.scrollY;
          if (currentScroll > lastScroll) {
            this.toggleMobileMenu();
            this.toggleMobileMenu();
          }
          this.wrapper.classList.toggle(
            "is-away--mobile",
            currentScroll > lastScroll
          );
          if (this.page.yearsList)
            this.page.yearsList.classList.toggle(
              "is-away--mobile",
              currentScroll < lastScroll &&
                currentScroll >= 120 &&
                !this.page.main.parent
            );
          lastScroll = currentScroll;
        });
      },
      false
    );
  }

  updateOnce() {
    this.cloneNavLinks();
  }

  update() {
    this.setCurrentLanguage();
    this.setCurrentLink();
    this.activeSearchBar();
    this.activePlace();
    this.activeMobileMenu();
    this.reloadOnLangChange();
    //this.updateStickyOnMobile();
  }
}
