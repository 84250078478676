export class MainAttributes {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.namespace = this.wrapper.getAttribute("data-barba-namespace");
    this.parent = this.wrapper.getAttribute("data-parent");
    if (this.namespace === "search") {
      this.noMatch = this.wrapper.getAttribute("data-search-nomatch");
      this.res = this.wrapper.getAttribute("data-search-res");
    }
  }
}

export class TitleElements {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.primary = this.wrapper.querySelector('.title__primary');
    this.second = this.wrapper.querySelector('.title__second');
    this.date = this.wrapper.querySelector('.title__date');
  }
}

export class SearchElements {
  constructor(wrapper, navbar) {
    this.wrapper = wrapper;
    this.submit = this.wrapper.querySelector(".search__submit");
    this.label = this.wrapper.querySelector(".search__label");
    this.input = this.wrapper.querySelector(".search__input");
    this.close = this.wrapper.querySelector(".search__close");
    this.check = navbar.querySelector("#show-search");
  }
}

export class PlacesElements {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.labels = [...this.wrapper.querySelectorAll("label")];
    this.inputs = [...this.wrapper.querySelectorAll("input")];
    this.noneLabel = this.wrapper.querySelector('[for="toggle-none"]');
    this.noneInput = this.wrapper.querySelector("#toggle-none");
  }
}

export class ModalElements {
  constructor(wrapper) {
    this.wrapper = wrapper;
    this.label = this.wrapper.querySelector("label")
    this.input = this.wrapper.querySelector("input")
  }
}