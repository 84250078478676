import {
  countLines,
  changeOnTransitionEnd,
  cloneAndReverseWord,
} from "../utilities/utils";

export class Helper {
  constructor(page) {
    this.page = page;
    this.list = this.page.exhibitList;
    this.exhibitLists = [...this.list.querySelectorAll(".list")];
    this.exhibitItems = [...this.list.querySelectorAll(".list__item")];
    this.exhibitLinks = [...this.list.querySelectorAll(".list__link")];
  }

  setYearsList() {
    if (!this.page.yearsList) return;
    const list = this.page.yearsList.querySelector("ul");
    const listItems = [...list.querySelectorAll("li")];
    list.classList.add("years-list");
    listItems.forEach((i) => i.classList.add("years-list__item"));
    this.yearsLinks = [
      ...this.page.yearsList.querySelectorAll(".years-list__item a"),
    ];
  }

  cloneYearsLinks() {
    if (!this.page.yearsList) return;
    changeOnTransitionEnd(this.page.yearsList, "a", "hover");
    this.yearsLinks.forEach((link) => {
      cloneAndReverseWord(link);
    });
  }

  setPillsPos() {
    this.exhibitLinks.forEach((link) => {
      const headline = link.querySelector(".headline");
      const pill = link.querySelector(".icon");
      if (!pill || !headline) return;
      const lines = countLines(headline);
      if (lines % 2 !== 0) {
        if (lines > 3) pill.style.top = "58%";
        if (lines > 1 && lines <= 3) pill.style.top = "66%";
        if (lines === 1) pill.style.top = "82%";
      }
      if (lines % 2 === 0) pill.style.top = "48%";
    });
  }

  updatePillsPos() {
    this.setPillsPos();
    window.addEventListener("resize", () => {
      if (!this.timeOut) {
        this.timeOut = setTimeout(() => {
          this.timeOut = null;
          this.setPillsPos();
        }, 100);
      }
    });
  }

  setCurrentYear() {
    if (!this.page.yearsList || !this.exhibitLists) return;
    if ("IntersectionObserver" in window) {
      const yearObserver = new IntersectionObserver(
        (entries) => {
          const entry = entries[0].target;
          if (entries[0].isIntersecting) {
            const year = entry.id;
            const currentYear = this.page.yearsList.querySelector(
              `[data-loc="${year}"]`
            );
            const scrollX = this.page.yearsList.scrollLeft;
            const targetX =
              currentYear.offsetLeft -
              currentYear.scrollLeft +
              currentYear.clientLeft;
            const diff = targetX - scrollX - 100;
            this.yearsLinks.forEach((link) =>
              link.classList.remove("is-active")
            );
            currentYear.classList.add("is-active");
            if (!diff) return;
            console.log(this.page.breakPoint);
            if (
              this.page.breakPoint === "xs" ||
              this.page.breakPoint === "sm"
            ) {
              this.page.yearsList.scrollTo({
                left: scrollX + diff,
                behavior: "smooth",
              });
            }
          }
        },
        {
          rootMargin: "-280px 0px 40px 0px",
        }
      );
      this.exhibitLists.forEach((list) => {
        yearObserver.observe(list);
      });
    }
  }

  displayHeroImage() {
    if (this.page.main.namespace !== "home") return;
    let mainImages = [];

    this.exhibitItems.forEach((item, idx) => {
      const targetUuid =
        item.firstElementChild.getAttribute("data-image-target");
      const mainImage = document.getElementById(targetUuid);
      if (mainImage) mainImages.push(mainImage);

      item.addEventListener("mouseover", (e) => {
        if (item.classList.contains("list__item--lentil")) return;
        mainImages.forEach((img) => img.classList.remove("is-active"));
        mainImages[idx].classList.add("is-active");
      });
      item.addEventListener("focusin", (e) => {
        if (item.classList.contains("list__item--lentil")) return;
        mainImages.forEach((img) => img.classList.remove("is-active"));
        mainImages[idx].classList.add("is-active");
      });
    });
    mainImages[0].classList.add("is-active");
  }

  update() {
    this.setYearsList();
    this.cloneYearsLinks();
    this.setPillsPos();
    this.updatePillsPos();
    this.setCurrentYear();
    this.displayHeroImage();
  }
}
