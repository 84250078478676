import {
  toggleClasses,
  toggleAttribute,
  createElemWithClass,
  getDistanceToFocalPoint,
  capitalized,
} from "../utilities/utils";

export class Helper {
  constructor(parent) {
    this.timeOut;
    this.carouselParent = parent;
    if (this.carouselParent) {
      this.carouselNav = this.carouselParent.getAttribute("data-carousel-nav");
      this.carouselType =
        this.carouselParent.getAttribute("data-carousel-type");
      if (this.carouselType === "image")
        this.carouselTrigger = document.querySelector(
          '[data-carousel="trigger"]'
        );
    }
  }

  renderCarouselItem(item) {
    const carouselItem = createElemWithClass({
      elemTag: "li",
      className: "carousel__item",
    });
    carouselItem.setAttribute("role", "item");
    carouselItem.setAttribute("data-carousel", "item");
    carouselItem.setAttribute("aria-label", "Carousel item");
    carouselItem.appendChild(item);
    return carouselItem;
  }

  untabCarouselElements() {
    if (this.carouselType !== "image") return;
    this.carouselRoot.setAttribute("tabindex", "-1");
    this.carouselParent.setAttribute("tabindex", "-1");
    this.prevButton.setAttribute("tabindex", "-1");
    this.nextButton.setAttribute("tabindex", "-1");
  }

  tabCarouselElements() {
    if (this.carouselType !== "image") return;
    this.carouselRoot.setAttribute("tabindex", "0");
    this.carouselParent.setAttribute("tabindex", "0");
    this.prevButton.setAttribute("tabindex", "0");
    this.nextButton.setAttribute("tabindex", "0");
  }

  renderCarousel() {
    this.items = [
      ...this.carouselParent.querySelectorAll('[data-carousel="item"]'),
    ];
    this.carouselRoot = createElemWithClass({ className: "carousel" });
    this.carouselContainer = createElemWithClass({
      className: "carousel__container",
      elemTag: "ul",
    });
    this.carouselContainer.setAttribute("role", "list");
    this.carouselRoot.setAttribute("role", "region");
    this.carouselRoot.setAttribute("aria-label", "Carousel");
    this.carouselRoot.appendChild(this.carouselContainer);
    this.carouselParent.appendChild(this.carouselRoot);

    this.items = this.items.map((media) => this.renderCarouselItem(media));
    this.items.forEach((item) => this.carouselContainer.appendChild(item));
  }

  createNavigationButton(direction, key) {
    const button = createElemWithClass({
      elemTag: "button",
      className: `carousel__navigation carousel__navigation--${direction}`,
    });
    button.setAttribute("aria-label", direction);
    button.setAttribute("data-direction", key);
    button.setAttribute("data-key", `Arrow${capitalized(key)}`);
    return button;
  }

  createNavigation() {
    this.navWrapper = createElemWithClass({
      className: "carousel__navigation__wrapper",
    });
    this.navWrapper.setAttribute("aria-label", "Navigation controls");
    this.navWrapper.setAttribute("data-carousel", "navigation");

    this.prevButton = this.createNavigationButton("prev", "left");
    this.nextButton = this.createNavigationButton("next", "right");

    this.navWrapper.appendChild(this.prevButton);
    this.navWrapper.appendChild(this.nextButton);

    this.carouselParent.appendChild(this.navWrapper);
  }

  enableNavigationButton() {
    const scrollLeft = Math.abs(this.carouselRoot.scrollLeft);
    const width = this.carouselRoot.clientWidth + 1;
    const isAtStart = Math.floor(scrollLeft) === 0;
    const isAtEnd =
      Math.ceil(width + scrollLeft) >= this.carouselRoot.scrollWidth;

    toggleAttribute(this.prevButton, isAtStart, "aria-disabled", true);
    toggleAttribute(this.nextButton, isAtEnd, "aria-disabled", true);
  }

  carouselEngine(direction) {
    let carouselItems = [
      ...this.carouselContainer.querySelectorAll(":scope > *"),
    ];
    carouselItems =
      direction === "left" ? carouselItems.reverse() : carouselItems;

    const carouselRootStart = getDistanceToFocalPoint(this.carouselRoot);
    let targetFocalPoint;
    for (const carouselItem of carouselItems) {
      const distanceToItem = getDistanceToFocalPoint(carouselItem);
      if (
        (direction === "left" && distanceToItem + 1 < carouselRootStart) ||
        (direction === "right" && distanceToItem - carouselRootStart > 1)
      ) {
        targetFocalPoint = distanceToItem;
        break;
      }
    }
    if (typeof targetFocalPoint === "undefined") return;
    const scrollAmount = targetFocalPoint - carouselRootStart;
    this.carouselRoot.scrollBy({ left: scrollAmount });
  }

  handleNavigation(target) {
    const direction = target.dataset.direction;
    const isDisabled = target.getAttribute("aria-disabled") === "true";
    if (isDisabled) return;
    this.carouselEngine(direction);
  }

  updateNextNav() {
    if (!this.nextButton) return;
    if (this.carouselType !== "text") {
      this.nextButton.removeAttribute("aria-disabled");
    } else {
      const carouselWidth = this.carouselRoot.offsetWidth;
      const carouselContainerWidth = this.carouselContainer.offsetWidth;
      const diff = carouselWidth - carouselContainerWidth;
      console.log(carouselWidth, carouselContainerWidth, diff);

      if (diff < 0) {
        this.nextButton.removeAttribute("aria-disabled");
      }
    }
  }

  resetCarousel() {
    if (this.carouselType !== "image") return;
    this.untabCarouselElements();
    this.carouselParent.classList.remove("is-active");
    setTimeout(() => {
      this.carouselRoot.scrollLeft = 0;
    }, 600);
  }

  runCarousel() {
    if (!this.carouselParent) return;
    this.carouselParent.addEventListener("click", (e) => {
      const target = e.target;
      if (target.matches(".carousel__navigation")) {
        this.handleNavigation(target);
      }
      if (target.matches("section")) this.resetCarousel();
    });
    document.addEventListener("keyup", (e) => {
      if (!this.carouselParent.classList.contains("is-active")) return;
      const keyPressed = e.key;
      if (keyPressed === "ArrowLeft" || keyPressed === "ArrowRight") {
        const target = this.carouselParent.querySelector(
          `[data-key="${keyPressed}"]`
        );
        this.handleNavigation(target);
      }
      if (keyPressed === "Backspace") this.resetCarousel();
    });
  }

  createCarousel() {
    if (!this.carouselParent) return;
    this.renderCarousel();
    this.createNavigation();
    this.enableNavigationButton();
    this.untabCarouselElements();
    //this.updateNextNav();
  }

  toggleCarousel() {
    if (this.carouselType !== "image") return;
    if (!this.carouselTrigger && !this.carouselParent) return;
    const footer = document.querySelector("footer");
    this.carouselTrigger.addEventListener("click", (e) => {
      this.tabCarouselElements();
      this.carouselRoot.scrollLeft = 0;
      this.carouselParent.classList.add("is-active");
      if (footer.classList.contains('is-away')) {
        footer.classList.remove('is-away')
      }
    });
  }

  update() {
    if (!this.carouselParent) return;
    this.createCarousel();
    this.toggleCarousel();
    this.runCarousel();
    this.carouselRoot.addEventListener(
      "scroll",
      (e) => {
        if (this.timeOut) {
          window.cancelAnimationFrame(this.timeOut);
        }
        this.timeOut = window.requestAnimationFrame(() => {
          this.enableNavigationButton();
        });
      },
      false
    );
  }
}
