import barba from "@barba/core";
import * as page from "./page";
import * as navbar from "./navbar";
import { toggleTransition, toggleClasses } from "../utilities/utils";

const pageHelper = new page.Helper();
const navbarHelper = new navbar.Helper(pageHelper);
const footer = document.querySelector("footer");

export function transitions() {
  barba.hooks.before(() => {
    barba.wrapper.classList.add("is-animating");
  });
  barba.hooks.after(() => {
    barba.wrapper.classList.remove("is-animating");
    const pageHelper = new page.Helper();
    pageHelper.update();
  });

  barba.init({
    transitions: [
      {
        name: "page-transition",
        beforeLeave({ current }) {
          const yearsList = document.querySelector('[data-list="years"]');
          if (yearsList) yearsList.classList.add("is-away");
          current.container.classList.remove("is-current");
          navbarHelper.hamburger.checked = false;
          navbarHelper.toggleMobileMenu();
          navbarHelper.wrapper.classList.remove("is-here");
          navbarHelper.wrapper.classList.add("is-away");
          footer.classList.add("is-away");
        },
        leave({ current }) {
          const done = this.async();
          current.container.classList.add("is-leaving");
          console.log(`I’m leaving — ${current.namespace}`);
          setTimeout(() => done(), 800);
        },
        beforeEnter({ next }) {
          const exhibitItems = [
            ...next.container.querySelectorAll(".list__item"),
          ];
          const title = next.container.querySelector(".title__primary");
          if (exhibitItems.length !== 0) {
            exhibitItems.forEach((item) => toggleTransition(item));
          }
          if (title) {
            toggleTransition(title);
          }
        },
        enter({ next }) {
          console.log(`I’m entering — ${next.namespace}`);
          const hash = window.location.hash;
          if (!hash) {
            window.scroll({
              top: 0,
              behavior: "instant",
            });
          } else {
            const targetLink = next.container.querySelector(
              `[data-loc="${hash.replace(/^#/, "")}"]`
            );
            const target = next.container.querySelector(
              `[data-hash-target="${hash.replace(/^#/, "")}"]`
            );
            console.log(target, targetLink);
            const padding = parseFloat(
              window.getComputedStyle(target).getPropertyValue("padding-top")
            );
            targetLink.classList.add("is-active");
            window.scroll({
              top: target.offsetTop - padding * 2.2,
            });
          }
        },
        afterEnter({ next }) {
          next.container.classList.add("is-current");
          navbarHelper.wrapper.classList.remove("is-away");
          navbarHelper.wrapper.classList.add("is-here");
          if (!next.container.hasAttribute("data-parent")) {
            footer.classList.remove("is-away");
          }
        },
      },
    ],
  });
}
