import {
  lazyLoadImages,
  getItemIndex,
  retrieveLanguage,
  toggleTransition,
  toggleSticky,
  setSizeAttrToPicture,
  getBreakpoint,
} from "../utilities/utils";
import * as get from "../utilities/getters";
import * as navbar from "./navbar";
import * as footer from "./footer";
import * as carousel from "./carousel";
import * as list from "./list";

export class Helper {
  constructor() {
    this.body = document.body;
    this.originURL = window.location.origin.toString();
    this.pixel = document.getElementById("pixel-anchor");
    this.mainWrapper = document.querySelector("main");
    this.main = new get.MainAttributes(this.mainWrapper);
    this.images = [...document.querySelectorAll(".image")];
    this.imagesLazy = [...document.querySelectorAll('[data-lazyload="main"]')];
    this.navbarHelper = new navbar.Helper(this);
    this.footerHelper = new footer.Helper(this);
    this.carouselParents = [
      ...document.querySelectorAll('[data-carousel="parent"]'),
    ];
    if (this.carouselParents) {
      this.carouselHelpers = [];
      this.carouselParents.forEach((parent) => {
        const helper = new carousel.Helper(parent);
        this.carouselHelpers.push(helper);
      });
    }
    this.exhibitList = document.querySelector('[data-list="exhibit"]');
    this.yearsList = document.querySelector('[data-list="years"]');
    this.footerList = document.querySelector('[data-list="footer"]');
    if (this.exhibitList) this.listHelper = new list.Helper(this);
    this.titleWrapper = document.querySelector(".title__wrapper");
    if (this.titleWrapper)
      this.title = new get.TitleElements(this.titleWrapper);
    this.modalWrapper = document.querySelector(".modal__wrapper");
    if (this.modalWrapper)
      this.modal = new get.ModalElements(this.modalWrapper);

    this.shrinkOptions = {
      threshold: 0.2,
    };
    this.breakPoint = getBreakpoint();
  }

  updateLangAttribute() {
    const currentLang = retrieveLanguage();
    document.documentElement.setAttribute("lang", currentLang);
  }

  updateLangLinks() {
    const links = [...document.querySelectorAll("a[href]")];
    links.forEach((link) => {
      let newHref;
      const languages = ["fr", "en"];
      const href = link.href.toString();
      const windowLoc = window.location.toString();
      const currentLang = retrieveLanguage();
      const targetLang = link.getAttribute("hreflang");
      if (href.includes(this.originURL)) {
        newHref =
          !link.classList.contains("navbar__language") && targetLang === null
            ? href.replace(
                href.split("/")[getItemIndex(languages, href.split("/"))],
                currentLang
              )
            : /* windowLoc.replace(
                windowLoc.split("/")[
                  getItemIndex(languages, windowLoc.split("/"))
                ],
                targetLang
              ); */ href

        link.setAttribute("href", newHref);
      }
    });
  }

  updateSearchBar() {
    const shouldUpdate = this.main.namespace === "search";
    this.navbarHelper.search.check.checked = false;
    if (shouldUpdate) {
      this.navbarHelper.search.check.checked = true;
      toggleTransition(this.navbarHelper.search.check);
    }
    this.navbarHelper.toggleSearchBar(this.navbarHelper.search.check);
    this.navbarHelper.toggleLinksFocus(this.navbarHelper.links);
  }

  upScaleHeadline() {
    if (!this.title || !this.pixel) return;
    const height = this.title.primary.offsetHeight;
    this.title.primary.setAttribute(
      "style",
      `--headline-height: ${height / 2}px`
    );
  }

  shrinkOnScroll() {
    if (!this.titleWrapper || !this.pixel || this.main.parent === "editions")
      return;
    if ("IntersectionObserver" in window) {
      const shrinkObserver = new IntersectionObserver(
        (entries) => {
          this.title.primary.classList.toggle(
            "headline--big",
            entries[0].isIntersecting
          );
        },
        { threshold: 1 }
      );
      shrinkObserver.observe(this.pixel);
    }
  }

  updateStickyPos(bp) {
    const article = this.mainWrapper.querySelector(".article__main");
    const articleFooter = this.mainWrapper.querySelector(".article__footer");
    if (!article && !articleFooter && !this.main.parent) return;
    const footerObserver = new IntersectionObserver(
      (entries) => {
        if (this.titleWrapper) {
          toggleSticky(
            this.titleWrapper,
            article,
            entries[0].isIntersecting,
            bp
          );
        }
        this.footerHelper.wrapper.classList.toggle(
          "is-away",
          !entries[0].isIntersecting
        );
      },
      { rootMargin: "-80px" }
    );
    footerObserver.observe(articleFooter);
  }

  hideModal() {
    if (!this.modalWrapper) return;
    this.modal.input.addEventListener("change", (e) => {
      this.modalWrapper.classList.toggle(
        "is-invisible",
        !this.modal.input.checked
      );
      this.navbarHelper.wrapper.removeAttribute("inert");
      this.mainWrapper.removeAttribute("inert");
      this.footerHelper.wrapper.removeAttribute("inert");
      setTimeout(() => {
        this.modalWrapper.remove();
        sessionStorage.setItem("modalClosed", "yes");
      }, 600);
    });
  }

  setUpModal() {
    if (!this.modalWrapper) return;
    this.navbarHelper.wrapper.setAttribute("inert", true);
    this.mainWrapper.setAttribute("inert", true);
    this.footerHelper.wrapper.setAttribute("inert", true);
    if (sessionStorage.getItem("modalClosed") === "yes") {
      console.log(sessionStorage.getItem("modalClosed"));
      this.modal.input.checked = false;
      this.navbarHelper.wrapper.removeAttribute("inert");
      this.mainWrapper.removeAttribute("inert");
      this.footerHelper.wrapper.removeAttribute("inert");
      this.modalWrapper.remove();
    }
    this.modal.input.checked = true;
    setTimeout(() => {
      this.modalWrapper.classList.remove("is-invisible");
    }, 100);
  }

  setCurrentYearExhibitPage() {
    if (!this.footerList && !this.main.parent) return;
    const exhibitList = this.footerList.querySelector('[data-list="exhibit"]');
    const yearsList = this.footerList.querySelector('[data-list="years"]');
    if (!exhibitList && !yearsList) return;
    const year = exhibitList.getAttribute("id");
    const currentYear = yearsList.querySelector(`[data-loc="${year}"]`);
    currentYear.classList.add("is-active");
  }

  updateOnce() {
    this.setUpModal();
    this.hideModal();
  }

  update() {
    this.updateLangAttribute();
    this.updateLangLinks();
    this.mainWrapper.classList.add("is-current");
    this.navbarHelper.update();
    this.images.forEach((image) => setSizeAttrToPicture(image));
    lazyLoadImages(this.imagesLazy, { rootMargin: "240px" });
    this.setCurrentYearExhibitPage();
    this.carouselHelpers.forEach((helper) => helper.update());
    if (this.exhibitList) this.listHelper.update();
    this.upScaleHeadline();
    this.shrinkOnScroll();
    this.updateSearchBar();
    this.updateStickyPos(this.breakPoint);
  }
}
