export class Helper {
  constructor(page) {
    this.page = page;
    this.wrapper = document.querySelector("footer");
    this.flashBannerWrapper = this.wrapper.querySelector(
      ".flash-banner__wrapper"
    );
    this.flashBanner = this.flashBannerWrapper.querySelector(".flash-banner");
    this.flashBannerItems = [...this.flashBanner.children];
  }

  cloneFlashItems() {
    this.flashBannerItems.forEach((item) => {
      let clone = item.cloneNode(true);
      clone.classList.add("clone");
      this.flashBanner.appendChild(clone);
    });
  }

  update() {
    this.cloneFlashItems();
  }
}