import * as u from "./utilities/utils.js";
import * as page from "./helpers/page.js";
import * as footer from "./helpers/footer.js";
import * as navbar from "./helpers/navbar.js";
import * as barba from "./helpers/barbaTransitions.js";

let timeOut;

const pageHelper = new page.Helper();
const navbarHelper = new navbar.Helper(pageHelper);
const footerHelper = new footer.Helper(pageHelper);

document.addEventListener("DOMContentLoaded", () => {
  barba.transitions();
  pageHelper.updateOnce();
  navbarHelper.updateOnce();
  pageHelper.breakPoint = u.getBreakpoint();
  pageHelper.update();
  footerHelper.update();
  u.toggleTransition(navbarHelper.searchBar);
});

window.addEventListener(
  "resize",
  () => {
    if (timeOut) {
      window.cancelAnimationFrame(timeOut);
    }
    timeOut = window.requestAnimationFrame(() => {
      pageHelper.breakPoint = u.getBreakpoint();
      pageHelper.imagesLazy.forEach((image) => u.setSizeAttrToPicture(image));
      pageHelper.updateStickyPos(pageHelper.breakPoint);
      pageHelper.carouselHelpers.forEach((helper) => helper.updateNextNav());
    });
  },
  false
);
